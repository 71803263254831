import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import useQueryGlobalMainNav from "../hooks/use-query-global-main-nav"
import useQueryGlobalFooter from "../hooks/use-query-global-footer"
import useQueryThemeOptions from "../hooks/use-query-themeOptions"
import "../assets/scss/main-style/main.scss"

// import Skeleton from 'react-loading-skeleton'
// import 'react-loading-skeleton/dist/skeleton.css'

export default function LayoutPage(props) {



  const { logo,logo_secondary, loginUrl, registerUrl } = useQueryThemeOptions().wp.themeOptions.themeOptions || {}
  const menusHeader = useQueryGlobalMainNav().wpMenu.menuItems.nodes?.filter(
    item => !item.parentId
  )
  const { menuItems, addOnGlobalFooterMenuItem: dataFooter } =
    useQueryGlobalFooter()?.wpMenu || {}
  const menusFooter = menuItems?.nodes?.filter(item => !item.parentId)
  const slug = props.slug
  return (
    <div className={slug + ' wrapper' }>
      <Header
        logo={logo || {}}
        logoSecondary={logo_secondary || {}}
        loginUrl={loginUrl || '/'}
        registerUrl={registerUrl || '/'}
        dataMenu={menusHeader}
        footerBottomList={dataFooter?.globalMenuFooterBottomList}
      />
      <main className="main">
        {props.children  }
        {/* <Skeleton count={10} >
         
        </Skeleton> */}
      </main>
      <Footer
        logo={dataFooter?.globalMenuFooterImage || logo || {}}
        menus={menusFooter}
        data={dataFooter}
      />
      {/* <Skeleton count={10} /> */}
    </div>
  )
}
