import { useStaticQuery, graphql } from "gatsby"

export default function useQueryGlobalMainNav() {
  return useStaticQuery(
    graphql`
      {
        wpMenu(locations: { eq: GLOBAL_MAIN }) {
          menuItems {
            nodes {
              id
              label
              url
              parentId
              cssClasses
              childItems {
                nodes {
                  id
                  label
                  url
                  cssClasses
                  customImageOnGlobalMainMenuItem {
                    globalMenuImage {
                      sourceUrl
                      altText
                      title
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                          )
                        }
                      }
                    }
                  }
                }
              }
              customImageOnGlobalMainMenuItem {
                globalMenuStatus
                globalMenuText
                globalMenuDesc
                globalMenuImage {
                  sourceUrl
                  altText
                  title
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
}
