import * as React from "react"
import useQueryThemeOptions from "../hooks/use-query-themeOptions"
const SocialLinks = () => {
  const { facebook, linkedin } =
    useQueryThemeOptions().wp.themeOptions.themeOptions?.socialMedia || {}
  return (
    <div className="links flex gap-2 text-[24px]">
      <a
        href={facebook || "#"}
        target="_blank"
        rel="noreferrer"
        className="hover:text-blue-100"
      >
        <i className={`icon-facebook`} />
      </a>
      {/* <a href={instagram || "#"} target="_blank" rel="noreferrer">
        <i className={`icon-instagram`} />
      </a>
      <a href={vimeo || "#"} target="_blank" rel="noreferrer">
        <i className={`icon-vimeo`} />
      </a> */}
      <a
        href={linkedin || "#"}
        target="_blank"
        rel="noreferrer"
        className="hover:text-blue-100"
      >
        <i className={`icon-linkedin`} />
      </a>
    </div>
  )
}

export default SocialLinks
