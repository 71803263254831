import * as React from "react"
import { Link } from "gatsby"

const MainMenu = ({
  footerBottomList,
  loginUrl,
  registerUrl,
  headerReft,
  ...props
}) => {
  const data = props.data || []
  const isBrowser = typeof window !== "undefined"
  let hashtag = ""
  if (isBrowser) hashtag = window.location.pathname
  let [statusMenu, setToggleMenu] = React.useState(null)
  const handleClick = id => {
    setToggleMenu(statusMenu === id ? null : id)
  }
  const handleScroll = e => {
    // setTimeout(() => {
    //   if (!headerReft.current) return
    //   headerReft.current.classList.remove("nav-down")
    //   headerReft.current.classList.add("nav-up")
    // }, 1000)
  }
  return (
    <>
      <nav className="main-navbar flex justify-end">
        <ul className="nav nav-pills lg:flex mb-0">
          {data.map((item, index) => (
            <li
              className={
                !!item.childItems.nodes.length
                  ? "nav-item menu-item-has-children"
                  : "nav-item"
              }
              key={item.id + index}
            >
              {item.url ? (
                <Link
                  data-active={
                    hashtag.split("/")[1] ===
                    item.url.replace(/^.*\/\/[^/]+/, "").split("/")[1]
                      ? "active"
                      : ""
                  }
                  to={item.url.replace(/^.*\/\/[^/]+/, "")}
                  className={
                    item.cssClasses.map(elm => elm).join(" ") +
                    " nav-link  p-3 text-14 font-semibold"
                  }
                >
                  {item.label}
                </Link>
              ) : (
                <span
                  role="presentation"
                  className="nav-link cursor-pointer"
                  onClick={() => handleClick(item.id)}
                >
                  {item.label}
                </span>
              )}
              <span
                className={
                  item.id === statusMenu
                    ? "current icon-arrow-down md:hidden"
                    : "icon-arrow-down md:hidden"
                }
                onClick={() => handleClick(item.id)}
                aria-hidden="true"
              />

              {item.childItems?.nodes?.length ? (
                <ul className="mega-menu ">
                  {item.childItems?.nodes?.map((subMenu, index) => (
                    <li className="nav-item" key={subMenu.id + index}>
                      <Link
                        onClick={e => handleScroll()}
                        to={subMenu.url || "#"}
                        className="nav-link text-14 font-semibold"
                      >
                        {subMenu.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </nav>
    </>
  )
}
export default MainMenu
