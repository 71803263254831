import React, { useEffect, useRef, useCallback } from "react"
import { Link } from "gatsby"
import { globalHistory } from "@reach/router"
import MainMenu from "./main-menu"
import { useScroll } from "../hooks/use-scroll"

const Header = ({
  logo,
  logoSecondary,
  loginUrl,
  registerUrl,
  dataMenu,
  footerBottomList,
}) => {
  const toggleMenu = useCallback(() => {
    let element = document.getElementsByTagName("html")
    element[0].classList.toggle("open-menu")
  }, [])

  const appHeaderRef = useRef(null)
  const lastScrollTop = useRef(0)
  const isMenuClicked = useRef(false)
  // useScroll(st => {
  //   if (isMenuClicked.current) {
  //     return
  //   }
  //   if (Math.abs(lastScrollTop.current - st) <= 100) return

  //   if (st > lastScrollTop.current) {
  //     appHeaderRef.current.classList.remove("nav-down")
  //     appHeaderRef.current.classList.add("nav-up")
  //   } else {
  //     if (st + window.innerHeight < document.documentElement.scrollHeight) {
  //       appHeaderRef.current.classList.remove("nav-up")
  //       appHeaderRef.current.classList.add("nav-down")
  //     }
  //   }
  //   if (st < appHeaderRef.current.offsetHeight) {
  //     appHeaderRef.current.classList.add("nav-top")
  //   } else {
  //     appHeaderRef.current.classList.remove("nav-top")
  //   }

  //   lastScrollTop.current = st
  // }, 5)

  useEffect(() => {
    return globalHistory.listen(({ action, location }) => {
      if (action === "PUSH") {
        let element = document.getElementsByTagName("html")
        element[0].classList.remove("open-menu")
      }
    })
  }, [])

  useEffect(() => {
    const hash = window.location.hash

    if (hash) {
      setTimeout(() => {
        const id = hash.replace("#", "")
        const element = document.getElementById(id)

        if (element) {
          element.scrollIntoView()
        }
      }, 10)
    }
  }, [])

  return (
    <>
      <header className="app-header" id="app-header" ref={appHeaderRef}>
        <div className="container lg:px-2 px-4  mx-auto">
          <div className="flex items-center justify-between">
            <div className="logo">
              <Link to="/">
                <img
                  className="logo-white"
                  src={logo.sourceUrl || "/images/logo.svg"}
                  title={logo.title}
                  alt={logo.altText}
                  srcSet={logo.srcSet}
                />
                <img
                  className="logo-black"
                  src={logoSecondary.sourceUrl || "/images/logo-black.svg"}
                  title={logoSecondary.title}
                  alt={logoSecondary.altText}
                  srcSet={logoSecondary.srcSet}
                />
              </Link>
            </div>
            <MainMenu
              headerReft={appHeaderRef}
              data={dataMenu}
              footerBottomList={footerBottomList}
              loginUrl={loginUrl}
              registerUrl={registerUrl}
            />

            <button
              onClick={toggleMenu}
              className="navbar-toggler collapsed p-0 lg:hidden"
              type="button"
              aria-label="Toggle navigation"
            >
              <i className="icon-menu"></i>
            </button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
