import { useStaticQuery, graphql } from "gatsby"

export default function useQueryGlobalFooter() {
  return useStaticQuery(
    graphql`
      {
        wpMenu(locations: { eq: GLOBAL_FOOTER }) {
          addOnGlobalFooterMenuItem {
            globalMenuFooterBottomItemTextRight
            globalMenuFooterBottomList {
              globalMenuFooterBottomItemText
              globalMenuFooterBottomItemLink {
                target
                title
                url
              }
            }
            globalMenuFooterImage {
              altText
              title
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
          menuItems {
            nodes {
              id
              url
              label
              parentId
              childItems {
                nodes {
                  label
                  url
                  id
                  parentId
                }
              }
            }
          }
        }
      }
    `
  )
}
