import { useStaticQuery, graphql } from "gatsby"

export default function useQueryThemeOptions() {
  return useStaticQuery(
    graphql`
      {
        wp {
          generalSettings {
            title
            url
            description
          }
          themeOptions {
            themeOptions {
              logo {
                sourceUrl
                altText
                srcSet
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
              }
              socialMedia {
                facebook
                linkedin
              }
              loginUrl
              registerUrl
            }
          }
        }
      }
    `
  )
}
