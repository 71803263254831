import * as React from "react"
import SocialLinks from "./social-links"
const Footer = ({ data }) => {
  return (
    <footer className="app-footer ">
      <div className="container mx-auto lg:px-2 px-4 py-6 flex justify-between items-start md:items-center">
        <div className="app-footer_left mr-3 mb-3 md:mb-0  flex  flex-wrap items-center gap-2">
          © Copyright {new Date().getFullYear()}{" "}
          {data.globalMenuFooterBottomItemTextRight}
          <ul className="nav flex flex-wrap mb-0">
            {data?.globalMenuFooterBottomList?.map(
              (
                {
                  globalMenuFooterBottomItemLink: link,
                  globalMenuFooterBottomItemText: text,
                },
                index
              ) => (
                <li className="nav-item" key={index}>
                  <a
                    className="nav-link underline hover:no-underline "
                    href={link?.url || "#"}
                    target={link?.target}
                    title={link?.title}
                  >
                    {text}
                  </a>
                </li>
              )
            )}
          </ul>
        </div>
        <div className="app-footer_right">
          <SocialLinks />
        </div>
      </div>
    </footer>
  )
}

export default Footer
