import React from "react"
import LayoutPage from "../components/layout-page"

const NotFoundPage = () => {
  return (
    <LayoutPage>
      <section className='title-block text-center tpl-block block-not-found'   >
        <div className='container mx-auto  py-6  min-h-screen'>
          <h2>404 Not Found</h2>
          <div className='title-block-text' >
            <p>
              This is somewhat embarrassing, isn’t it? <br />
              It seems we can’t find what you’re looking for.
            </p>
          </div>
        </div>
      </section>
    </LayoutPage>
  )
}

export default NotFoundPage
